<template>
    <h2>Prix des Nuggets au 3 février</h2>

     <EasyDataTable
    :headers="headers"
    :search-value="searchValue"
    :rows-per-page="items.length"
    :items="items"
    must-sort
  >
  <template #item-loc="{store_name, city, zip}">
    {{ store_name }} ({{ city }}  {{  zip !== 'null' ? zip : '' }})
  </template>
  <template #item-2078="item">

{{  printPrice(item['2078']) }}
</template>

  <template #item-2080="item">

{{  printPrice(item['2080']) }}
</template>
<template #item-2100="item">

{{  printPrice(item['2100']) }}
</template>

  </EasyDataTable>
</template>

<script>
import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import json from './assets/final_output.json';
export default {
  name: 'App',
  components: {  EasyDataTable },
  data: () => ({
    searchValue: '',
    serverOptions : {
  rowsPerPage: 5,
  sortBy: '2078',
  sortType: 'desc',
},
    headers: [
      {text: 'Localisation', value: 'loc', sortable: true},
      {text: 'boîte de 4', value: '2078', sortable: true},
      {text: 'Boite de 6', value: '2080',sortable: true},
       {text: 'Boite de 20', value: '2100', sortable: true}
    ],
  }) ,
  computed: {
    items() {
      return json.filter(j => j.store_name);
    }
  },
  methods: {
    printPrice(price) {
      return Number(price/100).toFixed(2)+"€"
    }
  }
}
</script>

<style>

</style>
